define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/consumer-financing-lenders/integration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EgbBwB7z",
    "block": "{\"symbols\":[\"MultiStepFormComponent\",\"@formMode\",\"@multiStepForm\",\"@lender\",\"&attrs\"],\"statements\":[[11,\"div\"],[17,5],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\"],[[32,0,[\"SwitchableFormStep\",\"progressBarConfig\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[32,3,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,[32,1],[],[[\"@formMode\",\"@multiStepForm\",\"@consumerFinancingLender\",\"@validateCredentialsTask\"],[[32,2],[32,3],[32,4],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"validateCredentialsTask\",\"formComponent\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/consumer-financing-lenders/integration/template.hbs"
    }
  });

  _exports.default = _default;
});