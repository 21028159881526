define("securitytrax-ember-ui/components/customer/financing-card/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/helpers/feature-is-enabled"], function (_exports, _component, _emberConcurrency, _featureIsEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var FinancingCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = (0, _emberConcurrency.task)(function* () {
    yield this.financingCreateOptionsTask.perform();
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    var lenderOptions = [];
    this.lenders = yield this.store.findAll('consumerFinancingLender', {
      include: 'consumer_financing_lender_type',
      reload: true
    });

    for (var i = 0; i < this.lenders.length; i++) {
      var lender = this.lenders.objectAt(i);

      if (lender.get('active')) {
        // Check lender enabled here too?
        if (lender.get('consumerFinancingLenderType') && (['2', '3'].includes(lender.get('consumerFinancingLenderType').get('id')) ? (0, _featureIsEnabled.featureIsEnabled)('dealerAllyFinancing') : true)) {
          lenderOptions.push(this.formatLenderDataTask.perform(lender));
        }
      }
    }

    this.financingCreateOptions = yield (0, _emberConcurrency.all)(lenderOptions);
  }), _dec17 = (0, _emberConcurrency.task)(function* (lender) {
    var lenderType = yield lender.get('consumerFinancingLenderType');
    return {
      packageName: lenderType.get('name') + ' - ' + lender.get('name'),
      packageId: lender.get('id')
    };
  }), _dec18 = (0, _emberConcurrency.task)(function* (row = null) {
    // This logic may need to be moved to BE as it makes the modal take forever to open, that or a global spinner of some sort overlaying the page.
    var lender = row ? yield row.get('consumerFinancingLender') : this.lenders.findBy('id', this.currentCreateId);
    var applicationState = row ? row.get('applicationState') : null;
    this.currentCreateId = row ? lender.get('id') : this.currentCreateId;
    this.lenderType = yield lender.get('consumerFinancingLenderType');
    this.applicationError = false;

    if (row) {
      if (this.lenderType.get('id') !== '1') {
        if (row.get('lenderOfferId')) {
          this.currentStepNameKey = 'contract';
          applicationState = applicationState === 'contracts_complete' ? applicationState : 'contract';
        }

        if ((applicationState === 'submitted' || applicationState === 'approved') && this.currentStepNameKey !== 'contract') {
          var getStatusResult = (yield row.invokeAction('get_status', {
            verb: 'POST',
            data: {
              data: {}
            }
          })).responsePayload.data;
          applicationState = getStatusResult.get_application_status.Status;

          if (getStatusResult.get_paperwork_status_ach.length > 0 && getStatusResult.get_paperwork_status_full.length && getStatusResult.get_paperwork_status_lcc.length) {
            applicationState = 'contract_complete';
          }
        }

        switch (applicationState) {
          case null:
            this.currentStepNameKey = 'review';
            break;

          case 'FilledOut':
            // Will go to review with the spinner showing
            this.processing = true;
            this.currentStepNameKey = 'review';
            break;

          case 'Approved':
          case 'pending':
          case 'declined':
            this.currentStepNameKey = 'decision';
            break;

          case 'Error':
            this.currentStepNameKey = 'decision';
            this.applicationError = true;
            break;

          case 'contract_complete':
            this.currentStepNameKey = 'documents';
            break;
        }
      } else {
        if (applicationState === 'pending' || applicationState === 'submitted' || applicationState === 'approved' || applicationState === 'declined') {
          (yield row.invokeAction('get_status', {
            verb: 'POST',
            data: {
              data: {}
            }
          })).responsePayload.data;
          this.currentStepNameKey = 'decision';
        } else {
          this.currentStepNameKey = 'review';
        }
      }
    }

    if (this.financingFormSteps !== null) {
      this.createFormChooserOpen = false;
      this.customerFinancing = row;
      this.customerFinancingFormMode = row ? 'edit' : 'create';
      this.customerFinancingFormStarted = true;
    } else {// Error cause no formSteps defined for lender (Lender isn't properly setup to integrate with)
    }
  }), _dec19 = Ember._action, (_class = (_temp = class FinancingCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "customerFinancing", _descriptor4, this);

      _initializerDefineProperty(this, "customerFinancingFormMode", _descriptor5, this);

      _initializerDefineProperty(this, "customerFinancingFormStarted", _descriptor6, this);

      _initializerDefineProperty(this, "lenders", _descriptor7, this);

      _initializerDefineProperty(this, "createFormChooserOpen", _descriptor8, this);

      _initializerDefineProperty(this, "currentCreateId", _descriptor9, this);

      _initializerDefineProperty(this, "financingCreateOptions", _descriptor10, this);

      _initializerDefineProperty(this, "currentStepNameKey", _descriptor11, this);

      _initializerDefineProperty(this, "lenderType", _descriptor12, this);

      _initializerDefineProperty(this, "processing", _descriptor13, this);

      _initializerDefineProperty(this, "applicationError", _descriptor14, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor15, this);

      _initializerDefineProperty(this, "financingCreateOptionsTask", _descriptor16, this);

      _initializerDefineProperty(this, "formatLenderDataTask", _descriptor17, this);

      _initializerDefineProperty(this, "openFinancingFormTask", _descriptor18, this);

      this.initDataTask.perform();
    }

    get createFormChooserButtons() {
      return [{
        text: this.intl.t('common.actions.start'),
        click: () => {
          this.openFinancingFormTask.perform();
        },
        classNamesString: 'st-btn st-btn-primary create-form-chooser-start'
      }];
    }

    get financingFormSteps() {
      if (this.lenderType) {
        switch (this.lenderType.get('id')) {
          case '1':
            return this.adcFinancingFormSteps;

          case '2':
          case '3':
            // May be different once pinnacle is actually done
            return this.dealerAllyFinancingFormSteps;
        }
      }

      return null;
    }

    get adcFinancingFormSteps() {
      var title = this.intl.t('pages.customers.accounting.financing.customer_financing');
      return Ember.Object.create({
        applicants: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.applicants'),
          stepNameKey: 'applicants',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/adc/applicants'
        }),
        loanDetails: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.details'),
          stepNameKey: 'loanDetails',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/adc/loan-details'
        }),
        review: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.review'),
          stepNameKey: 'review',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/adc/review'
        }),
        decision: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.decision'),
          stepNameKey: 'decision',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/adc/decision'
        })
      });
    }

    get dealerAllyFinancingFormSteps() {
      var title = this.intl.t('pages.customers.accounting.financing.customer_financing');
      return Ember.Object.create({
        applicants: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.applicants'),
          stepNameKey: 'applicants',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/dealer-ally/applicants'
        }),
        loanDetails: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.details'),
          stepNameKey: 'loanDetails',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/dealer-ally/loan-details'
        }),
        review: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.review'),
          stepNameKey: 'review',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/dealer-ally/review'
        }),
        decision: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.decision'),
          stepNameKey: 'decision',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/dealer-ally/decision'
        }),
        contract: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.contract'),
          stepNameKey: 'contract',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/dealer-ally/contract'
        }),
        documents: Ember.Object.create({
          progressBarTitle: this.intl.t('pages.customers.accounting.financing.documents'),
          stepNameKey: 'documents',
          title,
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/dealer-ally/documents'
        })
      });
    }

    get customerFinancingFormOriginalModels() {
      return Ember.Object.create({
        consumerFinancingApplication: {
          model: this.customerFinancing,
          copyRelationMap: {
            consumerFinancingApplicant: {
              doValidate: false,
              doSave: true,
              relationships: {
                customer: {
                  doValidate: false,
                  doSave: false
                },
                consumerFinancingApplicantsAdditionalIncomes: {
                  doValidate: false,
                  doSave: true
                }
              }
            },
            consumerFinancingLender: {
              doValidate: false,
              doSave: false,
              relationships: {
                consumerFinancingLenderType: {
                  doValidate: false,
                  doSave: false
                }
              }
            },
            consumerFinancingCoapplicant: {
              doValidate: false,
              doSave: true,
              relationships: {
                consumerFinancingApplicantsAdditionalIncomes: {
                  doValidate: false,
                  doSave: true
                }
              }
            }
          }
        }
      });
    }

    openCreateChooser() {
      if (this.financingCreateOptions.length > 1) {
        this.createFormChooserOpen = true;
        this.currentCreateId = this.financingCreateOptions[0].packageId;
      } else {
        this.currentCreateId = this.financingCreateOptions[0] ? this.financingCreateOptions[0].packageId : null;
        this.openFinancingFormTask.perform();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "customerFinancing", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "customerFinancingFormMode", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customerFinancingFormStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lenders", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "createFormChooserOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentCreateId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "financingCreateOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "currentStepNameKey", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'applicants';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "lenderType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "processing", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "applicationError", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "financingCreateOptionsTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "formatLenderDataTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "openFinancingFormTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openCreateChooser", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateChooser"), _class.prototype)), _class));
  _exports.default = FinancingCard;
});