define("securitytrax-ember-ui/validations/consumer-financing-applicant", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash", "moment"], function (_exports, _validate, _baseValidation, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.extend(_validate.default.validators.datetime, {
        parse: function (value) {
          return +_moment.default.utc(value);
        },
        format: function (value) {
          return _moment.default.utc(value).format('MM/DD/YYYY');
        }
      });

      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'consumerFinancingApplicant';

      _validate.default.validators.verifySsn = function (value, options) {
        if (value && value !== options.ssn) {
          return options.message;
        }

        return null;
      };

      var validateObject = {
        fname: {
          presence: this.customMessageOrDefault(data, 'fname')
        },
        lname: {
          presence: this.customMessageOrDefault(data, 'lname')
        },
        birthdate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-birthdate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'birthdate');
            }
          },
          datetime: {
            latest: _moment.default.utc((0, _moment.default)().subtract(18, 'years')),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_birth_date')
          }
        },
        ssn: {
          presence: this.customMessageOrDefault(data, 'ssn'),
          format: () => {
            if (this.ssn) {
              return {
                pattern: /[0-9]\d{3,}/,
                message: '^' + data.intlService.t('common.validations.customerSsn')
              };
            } else {
              return false;
            }
          }
        },
        verifySsn: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-verifySsn')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'verifySssn');
            }
          },
          verifySsn: {
            message: function () {
              return '^' + data.intlService.t('pages.customers.accounting.financing.must_match_ssn');
            },
            ssn: this.ssn
          }
        },
        idType: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idType');
            }
          }
        },
        idNumber: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idNumber')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idNumber');
            }
          }
        },
        idIssueDate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idIssueDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idIssueDate');
            }
          },
          datetime: {
            latest: _moment.default.utc(),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_issue_date')
          }
        },
        idExpirationDate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idExpirationDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idExpirationDate');
            }
          },
          datetime: {
            earliest: _moment.default.utc(),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_expiration_date')
          }
        },
        idStateOn: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idStateOn')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idStateOn');
            }
          }
        },
        address1: {
          presence: this.customMessageOrDefault(data, 'address1')
        },
        city: {
          presence: this.customMessageOrDefault(data, 'city')
        },
        state: {
          presence: this.customMessageOrDefault(data, 'state')
        },
        zip: {
          presence: this.customMessageOrDefault(data, 'zip'),
          format: {
            pattern: /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/,
            message: this.customMessage(data, 'zipInvalid')
          }
        },
        email: {
          presence: this.customMessageOrDefault(data, 'email')
        },
        employer: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-employer')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'employer');
            }
          }
        },
        employerStartDate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-employerStartDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'employerStartDate');
            }
          },
          datetime: {
            latest: _moment.default.utc(),
            earliest: _moment.default.utc((0, _moment.default)(this.birthdate).add(16, 'years')),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_start_date')
          }
        },
        grossMonthlyIncome: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-grossMonthlyIncome')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'grossMonthlyIncome');
            }
          }
        },
        cellPhone: {
          presence: this.customMessageOrDefault(data, 'cellPhone'),
          format: {
            pattern: /\d{9,15}/,
            //allow 9 for international, up to 15 since db accepts 15
            message: this.customMessage(data, 'phoneInvalid')
          }
        },
        residenceType: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-residenceType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'residenceType');
            }
          }
        },
        residenceMonthlyPayment: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-residenceMonthlyPayment')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'residenceMonthlyPayment');
            }
          }
        },
        homeValue: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-homeValue')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'homeValue');
            }
          }
        },
        employmentType: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-employmentType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'employmentType');
            }
          }
        },
        occupation: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-occupation')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'occupation');
            }
          }
        },
        livingSinceCurrentResidence: {
          datetime: {
            latest: _moment.default.utc(),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_moved_in_date')
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});